<template>
  <div class="row">
    <div class="col-md-12 card">
      <div class="card-header">
        <div class="title">Variants</div>
      </div>

      <div class="card-body">
        <div class="">
          <div class="category row bottom_margin_10">
            <el-tabs class="col-md-12" type="card" v-model="vars_selected_tab" @tab-click="show_tab_click">
              <el-tab-pane v-if="application === 'NGS-GENOVESA' || application === 'NGS-GENOVESA-DEMO'" label="ViennaLab Filter" name="b">
              </el-tab-pane>
              <el-tab-pane v-else label="Default Filter" name="b">
              </el-tab-pane>

              <el-tab-pane label="All Variants" name="x">
              </el-tab-pane>

              <el-tab-pane label="PolyT Analysis" name="t" v-if="g_project.polyt">
              </el-tab-pane>

              <el-tab-pane v-if="true" label="Custom filter" name="c">
                <vars_cufi>
                </vars_cufi>
              </el-tab-pane>

              <el-tab-pane v-if="rapp !== 'fastras' && false" label="Favourite Filters:" name="f">
                <div class="row">
                  <div class="col-md-6">
                    <form class="form-horizontal">
                      <h4 slot="header" class="card-title">
                        Save actual custom filter setting as new favourite filter
                      </h4>
                      <div class="row">
                        <label class="col-md-3 col-form-label">Name</label>
                        <div class="col-md-9">
                          <fg-input v-model="filter_name" placeholder=""></fg-input>
                        </div>

                        <label class="col-md-3 col-form-label">Description</label>
                        <div class="col-md-9">
                          <fg-input v-model="filter_description" placeholder=""></fg-input>
                        </div>

                        <label class="col-md-3 col-form-label"></label>
                        <div class="col-md-9">
                          <fg-input placeholder="">
                            <p-checkbox>Share filter</p-checkbox>
                          </fg-input>
                        </div>
                      </div>

                      <div slot="footer" class="row">
                        <div class="col-md-6 mr-auto ml-auto">
                          <el-tooltip content="My description ..." placement="top" effect="light">
                            <el-button type="info" block @click.native="a_filter_save"> SAVE </el-button>
                          </el-tooltip>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div class="col-md-6">
                    <el-button-group>
                      <el-button type="primary" size="small" icon="icofont-user-alt-2" @click.native="ff_type(0)"></el-button>
                      <el-button type="primary" size="small" icon="icofont-building-alt" @click.native="ff_type(1)"></el-button>
                      <el-button type="primary" size="small" icon="icofont-globe" @click.native="ff_type(2)"></el-button>
                    </el-button-group>

                    <el-table class=""
                              ref="f_filters"
                              :data="f_filters_data"
                              border
                              highlight-current-row
                              @current-change="handleCurrentChange_ff"
                              style="width: 100%"
                              size="medium"
                              empty-text=" "
                              >
                      <el-table-column v-for="column in tableColumns_ff"
                                      :key="column.label"
                                      :min-width="column.minWidth"
                                      :prop="column.prop"
                                      :label="column.label"
                                      :align="column.align"
                                      :sortable="column.sortable"
                                      >
                      </el-table-column>

                      <el-table-column width="70"
                                      header-align="right"
                                      label="Actions">
                        <div slot-scope="{row}" class="text-right">
                          <el-tooltip content="Info"
                                      :open-delay="300"
                                      placement="top">
                            <el-button type="info" size="sm" icon>
                              <i class="icofont-info-circle"></i>
                            </el-button>
                          </el-tooltip>

                          <el-tooltip content="Settings"
                                      :open-delay="300"
                                      placement="top">
                            <el-button type="success" size="sm" icon>
                              <i class="icofont-settings"></i>
                            </el-button>
                          </el-tooltip>

                          <el-tooltip content="Delete"
                                      :open-delay="300"
                                      placement="top">
                            <el-button type="danger" size="sm" icon>
                              <i class="icofont-trash"></i>
                            </el-button>
                          </el-tooltip>

                        </div>
                      </el-table-column>

                    </el-table>
                  </div>
                </div>
              </el-tab-pane>

              <el-tab-pane label="Preselected Variants" name="p">
              </el-tab-pane>

              <el-tab-pane label="Signed out Variants" name="s">
              </el-tab-pane>
            </el-tabs>

          </div>

          <div v-if="(g_examination) && (g_examination.status > 20) && (g_examination.status < 30)" class="category row bottom_margin_10">
            <el-tabs class="col-md-12" type="border-card" @tab-click="show_tab_click_1">
              <el-tab-pane label="Metadata:">
                <div class="row">
                  <div class="col-md-5 ml-auto mr-auto bg_gray">

                  </div>

                  <div class="col-md-5 ml-auto mr-auto bg_gray">

                  </div>
                </div>
              </el-tab-pane>

              <el-tab-pane label="Report:">
                <div class="col-md-12 bg_gray top_margin_10 bottom_margin_10">
                  <el-button-group>
                    <el-button type="primary" icon="el-icon-user-solid" @click.native="ff_type(0)"></el-button>
                    <el-button type="primary" icon="el-icon-office-building" @click.native="ff_type(1)"></el-button>
                    <el-button type="primary" icon="el-icon-rank" @click.native="ff_type(2)"></el-button>
                  </el-button-group>
                </div>

              </el-tab-pane>

            </el-tabs>
          </div>
        </div>


        <div v-if="vars_tab_name === 't'" class="row">
          <div class="col-sm-12 mt-2">
            <el-table
              :data="polyt_data"
              style="width: 100%"
              stripe
              :default-sort = "{prop: 'coverage', order: 'descending'}"
              >
              <el-table-column
                prop="sample_id"
                label="Sample ID"
                min-width="150"
                sortable
                >
              </el-table-column>

              <el-table-column
                prop="polyt"
                label="Genotype"
                align="center"
                sortable
                min-width="150"
                >
              </el-table-column>

              <el-table-column
                prop="coverage"
                label="Coverage"
                align="center"
                sortable
                min-width="150"
                >
              </el-table-column>

              <el-table-column
                prop="freq"
                label="Frequence (%)"
                align="center"
                sortable
                min-width="150"
                >
              </el-table-column>

              <el-table-column
                prop="prediction"
                label="Prediction"
                align="center"
                sortable
                min-width="150"
                >
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.prediction == true" type="danger"> HIGH </el-tag>
                    <el-tag v-else type="success"> LOW </el-tag>
                </template>
              </el-table-column>

              <el-table-column
                prop="report"
                label="Report"
                align="center"
                sortable
                min-width="150"
                >
                <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.report"> </el-checkbox>
                </template>
              </el-table-column>

            </el-table>
          </div>
        </div>

        <div v-else class="row">
          <div class="col-sm-1">
            <el-tooltip v-if="id_examination > 0 && selected_exams.length === 0" content="IGV" placement="top" effect="light" :open-delay="500">
              <el-checkbox v-model="igv_switch" label="IGV" border size="medium" @change="handle_igv"></el-checkbox>
            </el-tooltip>
          </div>

          <div class="col-sm-1">
            <span class="">
              <el-dropdown v-if="(id_project > 0) && (g_project.id_analysis_type > 0) && (g_examination_selected.status >= 12) && selected_exams.length === 0">
                <el-button type="warning" size="small">
                  <i class="icofont-list el-icon--right"></i>
                </el-button>
                <el-dropdown-menu v-if="id_biorole > 3" slot="dropdown">
                  <el-dropdown-item v-if="vars_selected_tab != 'p' && vars_selected_tab != 's' && g_examination.published.length === 0" icon="icofont-arrow-right" @click.native="preselect_vars($event)">Move to Preselected Variants</el-dropdown-item>
                  <el-dropdown-item v-if="vars_selected_tab === 'p' && g_examination.published.length === 0" icon="icofont-arrow-left" @click.native="unpreselect_vars($event)">Remove from Preselected Variants</el-dropdown-item>
                  <el-dropdown-item v-if="vars_selected_tab != 's' && g_examination.published.length === 0" icon="icofont-prescription" divided @click.native="sign_out_vars($event)">Sign-out variants</el-dropdown-item>
                  <el-dropdown-item v-if="vars_selected_tab != 's' && g_examination.published.length === 0" icon="icofont-law-document"  @click.native="sign_out_no_vars($event)">Sign-out with NO variants</el-dropdown-item>
                  <el-dropdown-item v-if="g_examination.published.length > 0" icon="icofont-ui-reply" @click.native="sign_out_vars_reset($event)">Sign-out RESET</el-dropdown-item>
                  <el-dropdown-item v-if="g_examination.published.length > 0" icon="icofont-file-pdf" divided @click.native="make_report_vars($event, g_examination.sample_id)">Make Report - PDF File</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </span>

            <span class="" style="margin-left: 10px">
              <span>&nbsp;</span>
            </span>

            <span class="">
              <el-dropdown>
                <el-button type="primary" size="small">
                  <i class="icofont-table el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item icon="icofont-file-excel" divided @click.native="export_all_vars($event)">Export ALL Variants into XLSX File</el-dropdown-item>
                  <el-dropdown-item icon="icofont-file-excel" @click.native="export_grid_vars($event)">Export Variants in Table into XLSX File</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </span>
          </div>

          <div class="col-sm-1">
            <span>&nbsp;</span>
          </div>

          <div class="col-sm-6">
            <el-input v-if="vars_selected_tab != 'p' && vars_selected_tab != 's'" placeholder="select column for filtering and insert value here ..." v-model="search_vars_by_value" class="input-with-select" size="small" @keyup.enter.native="search_vars_by">
              <el-select v-model="search_vars_by_select" size="small" slot="prepend" placeholder="Filter by:" style="width: 220px;">
                <el-option label="Position" value="1"></el-option>
                <el-option label="dbSNP" value="2"></el-option>
                <el-option label="Gene name" value="4"></el-option>
                <el-option label="COSMIC ID" value="3"></el-option>
              </el-select>
              <el-button slot="append" icon="icofont-search-2" size="small" @click="search_vars_by" style="margin: 0px 30px;"></el-button>
              <el-divider slot="append" direction="vertical"></el-divider>
              <el-button slot="append" icon="el-icon-close" size="small" @click="search_vars_all" style="margin: 0px 0px;"></el-button>
            </el-input>
          </div>

          <div class="col-sm-3">
            <span>&nbsp;</span>
          </div>

          <div class="col-sm-12 mt-2">
            <el-table
              ref="varsTable"
              :data="vars"
              row-key="id_ant"
              v-loading="loading_v"
              element-loading-text="Loading..."
              element-loading-spinner="icofont-refresh icofont-5x"
              element-loading-background="rgba(100, 100, 100, 0.5)"
              :default-sort="{prop: 'clinvar_weight', order: 'ascending'}"
              border
              highlight-current-row
              @current-change="handleCurrentChange_v"
              @selection-change="handleSelectionChange_v"
              @sort-change="handleSortChange_v"
              style="width: 100%"
              size="mini"
              :row-class-name="tableRowClassNameVars"
              :height="510"
              >
              <template slot="empty">
                  <div v-if="loading_v">
                  </div>
                  <div v-else style="font-size: 20px; font-weight: 800; text-align: left">
                    No variants found
                  </div>
              </template>
              <el-table-column type="expand" key="1">
                <template slot-scope="props">
                  <el-tabs type="card">
                    <el-tab-pane label="Main">
                      <el-row :gutter="5" class="min_height">
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_1"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_1 }}</div></el-col>
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_2"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_2 }}</div></el-col>
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_13"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_13 }}</div></el-col>
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_397"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_397 }}</div></el-col>
                      </el-row>
                      <el-row :gutter="5" class="min_height">
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_14"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_14 }}</div></el-col>
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_15"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_15 }}</div></el-col>
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_16"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_16 }}</div></el-col>
                      </el-row>
                      <el-row :gutter="5" class="min_height">
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_17"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_17 }}</div></el-col>
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_19"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_19 }}</div></el-col>
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_20"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_20 }}</div></el-col>
                      </el-row>
                      <el-row :gutter="5" class="min_height">
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_22"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_22 }}</div></el-col>
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_24"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_24 }}</div></el-col>
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_5"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_5 }}</div></el-col>
                      </el-row>
                    </el-tab-pane>

                    <el-tab-pane label="Frequencies Gnomad Exoms">
                      <el-row :gutter="5" class="min_height">
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_256"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_256 }}</div></el-col>
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_35"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_35 }}</div></el-col>
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_41"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_41 }}</div></el-col>
                      </el-row>
                      <el-row :gutter="5" class="min_height">
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_36"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_36 }}</div></el-col>
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_37"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_37 }}</div></el-col>
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_38"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_38 }}</div></el-col>
                      </el-row>
                      <el-row :gutter="5" class="min_height">
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_39"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_39 }}</div></el-col>
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_40"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_40 }}</div></el-col>
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_42"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_42 }}</div></el-col>
                      </el-row>
                      <el-row :gutter="5" class="min_height">
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_43"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_43 }}</div></el-col>
                        <el-col :span="2"><div class="grid-content text-right small_label">:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light"></div></el-col>
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_45"]}}:</div></el-col>
                        <el-col :span="3">
                          <el-popover
                            placement="top"
                            :title="d_a_cols['a_45']"
                            width="600"
                            trigger="click"
                            >
                            <div v-html="popover_items(props.row.a_45)">
                            </div>
                            <el-button v-if="props.row.a_45" slot="reference" size="mini">{{ (props.row.a_45).split(',')[0].substr(0,27) }}, ... </el-button>
                          </el-popover>
                        </el-col>
                      </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="Frequencies Gnomad genomes">
                      <el-row :gutter="5" class="min_height">
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_329"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_329 }}</div></el-col>
                        <el-col :span="2"><div class="grid-content text-right small_label">:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light"></div></el-col>
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_353"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_353 }}</div></el-col>
                      </el-row>
                      <el-row :gutter="5" class="min_height">
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_332"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_332 }}</div></el-col>
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_334"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_334 }}</div></el-col>
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_336"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_336 }}</div></el-col>
                      </el-row>
                      <el-row :gutter="5" class="min_height">
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_341"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_341 }}</div></el-col>
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_345"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_345 }}</div></el-col>
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_349"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_349 }}</div></el-col>
                      </el-row>
                      <el-row :gutter="5" class="min_height">
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_357"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_357 }}</div></el-col>
                      </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="Pathogenicity Scores">
                      <el-row :gutter="5" class="min_height">
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_77"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_77 }}</div></el-col>
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_78"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_78 }}</div></el-col>
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_79"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_79 }}</div></el-col>
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_215"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_215 }}</div></el-col>
                      </el-row>
                      <el-row :gutter="5" class="min_height">
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_143"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_143 }}</div></el-col>
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_144"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_144 }}</div></el-col>
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_169"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_169 }}</div></el-col>
                      </el-row>
                      <el-row :gutter="5" class="min_height">
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_170"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_170 }}</div></el-col>
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_195"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_195 }}</div></el-col>
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_193"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_193 }}</div></el-col>
                      </el-row>
                      <el-row :gutter="5" class="min_height">
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_210"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_210 }}</div></el-col>
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_209"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_209 }}</div></el-col>
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_216"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_216 }}</div></el-col>
                      </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="Conservation scores">
                      <el-row :gutter="5" class="min_height">
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_147"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_147 }}</div></el-col>
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_148"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_148 }}</div></el-col>
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_407"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_407 }}</div></el-col>
                      </el-row>
                      <el-row :gutter="5" class="min_height">
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_408"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_408 }}</div></el-col>
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_409"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_409 }}</div></el-col>
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_410"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_410 }}</div></el-col>
                      </el-row>
                      <el-row :gutter="5" class="min_height">
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_411"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_411 }}</div></el-col>
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_412"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_412 }}</div></el-col>
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_475"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_475 }}</div></el-col>
                      </el-row>
                      <el-row :gutter="5" class="min_height">
                        <el-col :span="2"><div class="grid-content text-right small_label">{{ d_a_cols["a_174"]}}:</div></el-col>
                        <el-col :span="3"><div class="grid-content bg-purple-light">{{ props.row.a_174 }}</div></el-col>
                      </el-row>
                    </el-tab-pane>
                  </el-tabs>
                </template>
              </el-table-column>

              <el-table-column key="selection"
                type="selection"
                width="45"
                >
              </el-table-column>

              <el-table-column key="sample_id"
                prop="sample_id"
                label="Sample"
                min-width="130"
                align="center"
                sortable="custom"
                >
                <template slot-scope="scope">
                  <span> {{ scope.row.sample_id }} </span> <br />
                  <el-tooltip v-if="scope.row.description_v > ''" :content="scope.row.description_v" placement="top" effect="light" :open-delay="500">
                    <el-tag size="mini" type="info">CM</el-tag>
                  </el-tooltip>
                  <el-tooltip v-if="scope.row.var_status === 2" content="Signed Out Variant" placement="top" effect="light" :open-delay="500">
                    <el-tag size="mini" effect="dark" type="success">SO</el-tag>
                  </el-tooltip>
                  <el-tooltip v-if="scope.row.var_status === 1" content="Preselected Variant" placement="top" effect="light" :open-delay="500">
                    <el-tag size="mini" effect="dark" type="warning">PS</el-tag>
                  </el-tooltip>
                </template>
              </el-table-column>

              <el-table-column key="int_sa"
                prop="int_sa"
                label="Cl. A"
                width="65"
                align="center"
                sortable="custom"
                >
                <template v-if="scope.row.int_sa >= 0" slot-scope="scope">
                  <el-tooltip :content="scope.row.iaa_user_name_a" placement="top" effect="light" :open-delay="500">
                    <el-button :style="g_iclasses_style(scope.row.int_sa)" size="small" @click.native="x_modal_interpretation(scope.row.id_var, scope.row.int_sa, 'a', scope.row.description_v)">{{ g_iclasses(scope.row.int_sa) }}</el-button>
                  </el-tooltip>
                </template>
                <template slot="header" slot-scope="scope">
                  <el-tooltip content="Internal classification by expert A" placement="top" effect="light" :open-delay="500">
                    <span>Cl. A</span>
                  </el-tooltip>
                </template>
              </el-table-column>

              <el-table-column key="int_sb"
                prop="int_sb"
                label="Cl. B"
                width="65"
                align="center"
                sortable="custom"
                >
                <template v-if="scope.row.int_sb >= 0" slot-scope="scope">
                  <el-tooltip :content="scope.row.iaa_user_name_b" placement="top" effect="light" :open-delay="500">
                    <el-button :style="g_iclasses_style(scope.row.int_sb)" size="small" @click.native="x_modal_interpretation(scope.row.id_var, scope.row.int_sb, 'b', scope.row.description_v)">{{ g_iclasses(scope.row.int_sb) }}</el-button>
                  </el-tooltip>
                </template>
                <template slot="header" slot-scope="scope">
                  <el-tooltip content="Internal classification by expert B" placement="top" effect="light" :open-delay="500">
                    <span>Cl. B</span>
                  </el-tooltip>
                </template>
              </el-table-column>

              <el-table-column key="id_acmg_sig"
                prop="id_acmg_sig"
                label="ACMG"
                width="65"
                align="center"
                sortable="custom"
                >
                <template slot-scope="scope">
                  <div v-if="scope.row.id_acmg_sig > 0">
                      <el-popover placement="top-start" title="ACMG Description:" width="400" trigger="hover" :content="d_acmg_sigs[scope.row.id_acmg_sig]['mytext']">
                        <el-button slot="reference" :style="d_acmg_sigs[scope.row.id_acmg_sig]['mystyle']" size="small" @click.native="click_acmg($event, scope.row.id_var)">{{ d_acmg_sigs[scope.row.id_acmg_sig]["mynum"] }}</el-button>
                      </el-popover>
                  </div>
                </template>
              </el-table-column>

              <el-table-column key="clinvar_weight"
                prop="clinvar_weight"
                label="ClinVar"
                width="70"
                align="center"
                sortable="custom"
                >
                <template v-if="scope.row.clinvar_value >= 0" slot-scope="scope">
                  <a v-if="scope.row.clinvar_value > 0" :href="mylink_clinsig(scope.row)" target="_blank">
                      <el-popover v-if="scope.row.clinvar_value === 5"
                        placement="top-start" title="Clinvar Description:" width="400" trigger="hover" :content="scope.row.a_885">
                        <el-tag slot="reference" type="danger" class="hranaty">{{ scope.row.clinvar_value }}</el-tag>
                      </el-popover>

                      <el-popover v-else-if="scope.row.clinvar_value === 4"
                        placement="top-start" title="Clinvar Description:" width="400" trigger="hover" :content="scope.row.a_885">
                        <el-tag slot="reference" type="warning" class="hranaty">{{ scope.row.clinvar_value }}</el-tag>
                      </el-popover>

                      <el-popover v-else-if="scope.row.clinvar_value === 3"
                        placement="top-start" title="Clinvar Description:" width="400" trigger="hover" :content="scope.row.a_885">
                        <el-tag slot="reference" type="info" class="hranaty">{{ scope.row.clinvar_value }}</el-tag>
                      </el-popover>

                      <el-popover v-else
                        placement="top-start" title="Clinvar Description:" width="400" trigger="hover" :content="scope.row.a_885">
                        <el-tag slot="reference" type="success" class="hranaty">{{ scope.row.clinvar_value }}</el-tag>
                      </el-popover>
                  </a>
                  <div v-else>
                      <el-popover v-if="scope.row.clinvar_value === 5"
                        placement="top-start" title="Clinvar Description:" width="400" trigger="hover" :content="scope.row.a_885">
                        <el-tag slot="reference" type="danger" class="hranaty">{{ scope.row.clinvar_value }}</el-tag>
                      </el-popover>

                      <el-popover v-else-if="scope.row.clinvar_value === 4"
                        placement="top-start" title="Clinvar Description:" width="400" trigger="hover" :content="scope.row.a_885">
                        <el-tag slot="reference" type="warning" class="hranaty">{{ scope.row.clinvar_value }}</el-tag>
                      </el-popover>

                      <el-popover v-else-if="scope.row.clinvar_value === 3"
                        placement="top-start" title="Clinvar Description:" width="400" trigger="hover" :content="scope.row.a_885">
                        <el-tag slot="reference" type="info" class="hranaty">{{ scope.row.clinvar_value }}</el-tag>
                      </el-popover>

                      <el-popover v-else
                        placement="top-start" title="Clinvar Description:" width="400" trigger="hover" :content="scope.row.a_885">
                        <el-tag slot="reference" type="success" class="hranaty">{{ scope.row.clinvar_value }}</el-tag>
                      </el-popover>
                  </div>
                </template>
              </el-table-column>

              <el-table-column key="f_proj"
                prop="f_proj"
                label="Freq / Proj"
                width="100"
                align="center"
                >
                <template slot-scope="scope">
                  <el-badge type="primary" :value="scope.row.e_proj" class="badge_item">
                    <el-button size="small" @click.native="click_freq_proj(scope.row)"> {{ scope.row.f_proj }} </el-button>
                  </el-badge>
                </template>
              </el-table-column>

              <el-table-column v-if="g_project.id_analysis_type === 1" key="f_wf1"
                prop="f_wf1"
                label="Freq / Som"
                width="100"
                align="center"
                >
                <template slot-scope="scope">
                  <el-badge type="info" :value="scope.row.e_wf1" class="badge_item">
                    <el-button size="small" @click.native="click_freq_subj(scope.row)"> {{ scope.row.f_wf1 }} </el-button>
                  </el-badge>
                </template>
              </el-table-column>

              <el-table-column v-if="g_project.id_analysis_type === 2" key="f_wf2"
                prop="f_wf2"
                label="Freq / Germ"
                min-width="110"
                align="center"
                >
                <template slot-scope="scope">
                  <el-badge type="info" :value="scope.row.e_wf2" class="badge_item">
                    <el-button size="small" @click.native="click_freq_subj(scope.row)"> {{ scope.row.f_wf2 }} </el-button>
                  </el-badge>
                </template>
              </el-table-column>

              <el-table-column key="a_4"
                prop="a_4"
                label="Gene"
                min-width="160"
                align="center"
                sortable="custom"
                >
                <template v-if="scope.row.a_4 > ''" slot-scope="scope">
                    <a v-if="scope.row.a_4 > ''" :href="mylink_symbol(scope.row.a_4)" target="_blank">{{ scope.row.a_4 }}</a> <br /> <a v-if="scope.row.a_7 > ''" :href="mylink_transcript(scope.row)" target="_blank">{{ scope.row.a_7 }}</a>
                </template>
              </el-table-column>

              <el-table-column key="a_891"
                prop="a_891"
                label="dbSNP"
                min-width="100"
                align="center"
                sortable="custom"
                >
                <template v-if="scope.row.a_891 > ''" slot-scope="scope">
                    <a :href="mylink_dbsnp(scope.row.a_891)" target="_blank">{{ scope.row.a_891 }}</a>
                    <br /> <a :href="mylink_varsome(scope.row.a_891)" target="_blank">VRS</a> <span> &nbsp; </span> <a :href="mylink_genebe(scope.row)" target="_blank">GNB</a>
                </template>
              </el-table-column>

              <el-table-column key="a_11"
                prop="a_11"
                label="HGVSc / HGVSp"
                min-width="165"
                align="center"
                >
                <template v-if="scope.row.a_11 > ''" slot-scope="scope">
                    <a :href="mylink_hgvsc(scope.row)" target="_blank">{{ scope.row.a_11 }}</a> <br /> {{ scope.row.a_12 }}
                </template>
              </el-table-column>

              <el-table-column key="freq"
                prop="freq"
                label="VAF"
                min-width="65"
                align="center"
                sortable="custom"
                >
              </el-table-column>

              <el-table-column key="dp"
                prop="dp"
                label="Total DP"
                min-width="90"
                align="center"
                sortable="custom"
                >
                <template slot-scope="scope">
                    {{ scope.row.rd + scope.row.ad }}
                </template>
              </el-table-column>

              <el-table-column key="ad"
                prop="ad"
                label="Ref : Alt DP"
                min-width="110"
                align="center"
                sortable="custom"
                >
                <template slot-scope="scope">
                    {{ scope.row.rd }} : {{ scope.row.ad }}
                </template>
                <template slot="header" slot-scope="scope">
                  <el-tooltip content="Sorted by Alt Depth" placement="top" effect="light" :open-delay="500">
                    <span>Ref : Alt DP</span>
                  </el-tooltip>
                </template>
              </el-table-column>

              <el-table-column key="a_22"
                prop="a_22"
                label="Type"
                min-width="90"
                align="center"
                sortable="custom"
                >
                <template slot-scope="scope">
                  <span>{{ scope.row.a_22 }}</span>
                  <br v-if="scope.row.split_reads" />
                  <el-tag v-if="scope.row.split_reads" size="mini" type="info">SR</el-tag>
                </template>
              </el-table-column>

              <el-table-column key="a_2"
                prop="a_2"
                label="Consequence"
                min-width="165"
                align="center"
                sortable="custom"
                >
              </el-table-column>

              <el-table-column key="filter_text"
                prop="filter_text"
                label="Var Qual"
                min-width="78"
                align="center"
                >
                <template slot-scope="scope">
                  <el-tooltip v-if="scope.row.filter.length > 0" placement="top" effect="light" :open-delay="500">
                    <div  slot="content">
                      <p v-for="one of scope.row.filter">
                        {{ d_filter[one] }}
                      </p>
                    </div>
                    <span> {{ scope.row.filter_text }} </span>
                  </el-tooltip>
                  <span v-else> {{ scope.row.filter_text }} </span>
                </template>

              </el-table-column>

              <el-table-column key="gt"
                prop="gt"
                label="GT"
                min-width="60"
                align="center"
                sortable="custom"
                >
                <!-- <template slot-scope="scope">
                  <span v-if="scope.row.het === 1">HET</span>
                  <span v-else-if="scope.row.hom === 1">HOM</span>
                  <span v-else> - </span>
                </template> -->
              </el-table-column>

              <el-table-column key="a_44"
                prop="a_44"
                label="MAX AF"
                min-width="110"
                align="center"
                sortable="custom"
                >
              </el-table-column>

              <el-table-column key="a_3"
                prop="a_3"
                label="Impact"
                min-width="95"
                align="center"
                sortable="custom"
                >
              </el-table-column>

              <el-table-column key="pos"
                prop="pos"
                label="Chr:Position"
                min-width="140"
                align="center"
                sortable="custom"
                >
                <template slot-scope="scope">
                  <a :href="mylink_pos(scope.row)" target="_blank">{{ scope.row.chrom }}:{{ scope.row.pos }}</a> <br /> <el-link v-if="igv_switch" @click.native="igv_search(scope.row, $event)" icon="icofont-look" class="igv_modra"> IGV </el-link>
                </template>
              </el-table-column>

              <el-table-column key="a_501"
                prop="a_501"
                label="COSMIC"
                min-width="120"
                align="center"
                >
                <template v-if="scope.row.a_501 > ''" slot-scope="scope">
                    <a :href="mylink_cosmic(scope.row)" target="_blank">{{ scope.row.a_501 }}</a>
                </template>
              </el-table-column>

              <el-table-column key="abq"
                prop="abq"
                label="Alt Var Qual"
                min-width="100"
                align="center"
                >
              </el-table-column>

              <el-table-column  key="id_var"
                prop="id_var"
                label="DB ID"
                min-width="90"
                align="center"
                sortable="custom"
                >
              </el-table-column>

              <el-table-column key="27"
                :width="85"
                fixed="right"
                class-name="td-actions"
                label="Actions"
                >
                <template slot-scope="props">
                  <el-tooltip content="Show all transcripts" placement="top" effect="light" :open-delay="500">
                    <el-button type="default" size="sm" icon @click="handle_transcripts( props.$index, props.row, $event)">
                      <i class="icofont-listing-box"></i>
                    </el-button>
                  </el-tooltip>

                  <!-- <el-button type="default" size="sm" icon @click.native="show = !show"> <i class="icofont-listing-box"></i> </el-button>
                  <el-button type="default" size="sm" icon @click.native="$igv.browser('two').search('chr1:102987575-102987633')"> <i class="icofont-listing-box"></i> </el-button>
                  <el-button type="default" size="sm" icon @click.native="$igv.destroy('two')"> <i class="icofont-listing-box"></i> </el-button> -->

                </template>
              </el-table-column>

            </el-table>
          </div>

          <div class="col-sm-12">
            <el-pagination
              class="pull-right pagination_mezera"
              @size-change="handleSizeChangePage_v"
              @current-change="handleCurrentChangePage_v"
              :current-page.sync="pagination_v.currentPage"
              :page-sizes="pagination_v.perPageOptions"
              :page-size="pagination_v.perPage"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pagination_v.total"
              background
              >
            </el-pagination>
          </div>
        </div>

        <el-divider slot="append" direction="horizontal"></el-divider>

        <div v-if="igv_switch && !global">
          <div id="igv-div"></div>
        </div>

        <!-- <div id="igv-div"></div> -->

      </div>


      <el-dialog :visible.sync="modal_interpretation" width="95%" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="true">
        <h5 slot="header" class="title title-up"> Change Internal Class: </h5>
        <div class="row">
          <el-tabs class="col-md-12" type="card" @tab-click="iclass_tabs" v-model="mytab">
            <el-tab-pane :disabled="!formdata_change_class.pane_a" label="Change value A" name="change_a">
              <form @submit.prevent="submit_formdata_change_class">
                <div class="row">
                  <div class="col-md-11 mr-auto ml-auto">
                      <label>Internal Classification A</label>
                      <div class="form-group">
                        <el-select class="select-info"
                                  placeholder="Select value"
                                  v-model="formdata_change_class.class_value_a">
                          <el-option v-for="one in l_iclasses"
                                    class="select-info"
                                    :value="one.id_iclass"
                                    :label="one.iclass"
                                    :key="one.id_iclass">
                          </el-option>
                        </el-select>
                      </div>

                      <label>Description of Change</label>
                        <el-input
                          type="textarea"
                          :rows="4"
                          autosize
                          maxlength="120"
                          v-model="formdata_change_class.description_a"
                          show-word-limit
                        >
                        </el-input>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-11 mr-auto ml-auto">
                    <button type="submit" class="btn btn-info" style="width: 100%">Submit</button>
                  </div>
                </div>
              </form>
            </el-tab-pane>

            <el-tab-pane :disabled="!formdata_change_class.pane_b" label="Change value B" name="change_b">
              <form @submit.prevent="submit_formdata_change_class">
                <div class="row">
                  <div class="col-md-11 mr-auto ml-auto">
                      <label>Internal Classification B</label>
                      <div class="form-group">
                        <el-select class="select-info"
                                  placeholder="Select value"
                                  v-model="formdata_change_class.class_value_b">
                          <el-option v-for="one in l_iclasses"
                                    class="select-info"
                                    :value="one.id_iclass"
                                    :label="one.iclass"
                                    :key="one.id_iclass">
                          </el-option>
                        </el-select>
                      </div>

                      <label>Description of Change</label>
                        <el-input
                          type="textarea"
                          :rows="4"
                          autosize
                          maxlength="120"
                          v-model="formdata_change_class.description_b"
                          show-word-limit
                        >
                        </el-input>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-11 mr-auto ml-auto">
                    <button type="submit" class="btn btn-info" style="width: 100%">Submit</button>
                  </div>
                </div>
              </form>
            </el-tab-pane>

            <el-tab-pane label="Variant Comments" name="vardescription">
              <form @submit.prevent="submit_formdata_change_class">
                <div class="row">
                  <div class="col-md-11 mr-auto ml-auto">
                    <div class="form-group">
                      <textarea type="textarea" :rows="15" class="form-control" placeholder="" v-model="formdata_change_class.description_v"> </textarea>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-11 mr-auto ml-auto">
                    <button type="submit" class="btn btn-info" style="width: 100%">Submit</button>
                  </div>
                </div>
              </form>
            </el-tab-pane>

            <el-tab-pane label="History" name="history">
              <el-table
                :data="ints_history_data"
                stripe
                style="width: 100%"
                >
                <el-table-column
                  prop="iaa_user_name_a"
                  label="User Name A"
                  width="200"
                  align="center"
                  >
                </el-table-column>

                <el-table-column
                  prop="iclass_a"
                  label="IClass A"
                  width="200"
                  align="center"
                  >
                  <template v-if="scope.row.int_sa >= 0" slot-scope="scope">
                      <el-button :style="g_iclasses_style(scope.row.int_sa)" size="small">{{ scope.row.iclass_a }}</el-button>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="iaa_user_name_b"
                  label="User Name B"
                  width="200"
                  align="center"
                  >
                </el-table-column>

                <el-table-column
                  prop="iclass_b"
                  label="IClass B"
                  width="200"
                  align="center"
                  >
                  <template v-if="scope.row.int_sb >= 0" slot-scope="scope">
                      <el-button :style="g_iclasses_style(scope.row.int_sb)" size="small">{{ scope.row.iclass_b }}</el-button>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="made"
                  label="Made"
                  width="180"
                  align="center"
                  >
                </el-table-column>

                <el-table-column
                  prop="int_ta"
                  label="Note A"
                  >
                </el-table-column>

                <el-table-column
                  prop="int_tb"
                  label="Note B"
                  >
                </el-table-column>
              </el-table>
            </el-tab-pane>

            <el-tab-pane label="Partners" name="partners">
              <el-table
                :data="ints_partner_data"
                stripe
                style="width: 100%"
                >

                <el-table-column
                  prop="subject"
                  label="Partner"
                  min-width="300"
                  sortable
                  >
                </el-table-column>

                <el-table-column
                  prop="user_a"
                  label="User Name A"
                  min-width="200"
                  sortable
                  align="center"
                  >
                </el-table-column>

                <el-table-column
                  prop="t_int_sa"
                  label="IClass A"
                  min-width="180"
                  sortable
                  align="center"
                  >
                  <template v-if="scope.row.int_sa >= 0" slot-scope="scope">
                      <el-button :style="g_iclasses_style(scope.row.int_sa)" size="small">{{ scope.row.t_int_sa }}</el-button>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="user_b"
                  label="User Name B"
                  min-width="200"
                  sortable
                  align="center"
                  >
                </el-table-column>

                <el-table-column
                  prop="t_int_sb"
                  label="IClass B"
                  min-width="180"
                  sortable
                  align="center"
                  >
                  <template v-if="scope.row.int_sb >= 0" slot-scope="scope">
                      <el-button :style="g_iclasses_style(scope.row.int_sb)" size="small">{{ scope.row.t_int_sb }}</el-button>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="made"
                  label="Made"
                  min-width="200"
                  sortable
                  align="center"
                  >
                </el-table-column>

                <el-table-column
                  prop="int_ta"
                  label="Note A"
                  min-width="300"
                  >
                </el-table-column>

                <el-table-column
                  prop="int_tb"
                  label="Note B"
                  min-width="300"
                  >
                </el-table-column>
              </el-table>
            </el-tab-pane>

          </el-tabs>
        </div>
      </el-dialog>


      <el-dialog :visible.sync="modal_transcripts" width="70%" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="true" center>
        <h5 slot="title" class="title title-up to_middle">Transcripts</h5>
        <div class="row">
          <el-table class=""
                    ref="Transcripts"
                    :data="transcripts"
                    border
                    highlight-current-row
                    @current-change=""
                    style="width: 100%"
                    size="medium"
                    :row-class-name="tableRowClassName"
                    empty-text=" "
                    >
            <el-table-column v-for="column in tableColumns_transcripts"
                            :key="column.label"
                            :min-width="column.minWidth"
                            :prop="column.prop"
                            :label="column.label"
                            :align="column.align"
                            :sortable="column.sortable"
                            >
            </el-table-column>
          </el-table>
        </div>
      </el-dialog>


      <el-dialog :visible.sync="modal_pseudopanels" width="60%" :close-on-click-modal="false" :close-on-press-escape="true" :show-close="true" center>
        <h5 slot="title" class="title title-up to_middle">Pseudopanels</h5>

      </el-dialog>


      <el-dialog :visible.sync="modal_acmg" width="75%" :close-on-click-modal="false" :close-on-press-escape="true" :show-close="true">
        <h5 slot="title" class="title"> ACMG classification </h5>
        <el-row :gutter="50">
          <el-col :span="24">
            <el-table :data="acmg_data" stripe style="width: 100%">
              <el-table-column prop="acmg_code" label="ACMG rule" width="200">
              </el-table-column>
              <el-table-column prop="acmg_text" label="Explanation">
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </el-dialog>


      <el-dialog :visible.sync="modal_freq" width="85%" :close-on-click-modal="false" :close-on-press-escape="true" :show-close="true">
        <h5 slot="title" class="title"> {{ freq_type }} </h5>
        <el-row :gutter="50">
          <el-col :span="24">
            <el-table
              :data="freq_data"
              stripe
              style="width: 100%"
              height="500"
              >
              <el-table-column
                prop="id_project"
                label="Project ID"
                min-width="100"
                sortable
                align="center"
                >
              </el-table-column>
              <el-table-column
                prop="name"
                label="Project name"
                min-width="200"
                sortable
                align="center"
                >
              </el-table-column>
              <el-table-column
                prop="id_examination"
                label="Sample ID"
                min-width="100"
                sortable
                align="center"
                >
              </el-table-column>
              <el-table-column
                prop="sample_id"
                label="Sample name"
                min-width="200"
                sortable
                align="center"
                >
              </el-table-column>
              <el-table-column
                prop="public_name"
                label="Workflow"
                min-width="150"
                sortable
                align="center"
                >
              </el-table-column>
              <el-table-column
                prop="freq"
                label="VAF"
                min-width="200"
                sortable
                align="center"
                >
              </el-table-column>
              <el-table-column
                prop="dp"
                label="Total Depth"
                min-width="100"
                sortable
                align="center"
                >
              </el-table-column>
              <el-table-column
                prop="gt"
                label="Genotype"
                min-width="100"
                sortable
                align="center"
                >
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </el-dialog>


      <el-dialog title="Human Phenotype Ontology" :visible.sync="modal_hpo_terms" width="80%" :close-on-click-modal="false" :close-on-press-escape="true" :show-close="true">
        <h5 slot="header" class="title title-up">  </h5>

        <div class="row bottom_margin_10 bg_gray">
          <div class="col-sm-12">
            <div class="col-sm-6">
              <el-input
                v-model="searchQuery_hpo"
                size=""
                class="top_margin_10"
                placeholder="Type to search HPO" />
            </div>
            <div class="col-sm-6">
              <div class="pull-right">

              </div>
            </div>
            <div class="col-sm-12 mt-2">
              <el-table class=""
                        ref="hpo"
                        :data="hpo_data.filter(data => !searchQuery_hpo || data.hpo_term_name.toLowerCase().includes(searchQuery_hpo.toLowerCase()))"
                        border
                        highlight-current-row
                        @current-change="handleCurrentChange_hpo"
                        style="width: 100%"
                        size="medium"
                        empty-text=" "
                        >
                <el-table-column
                  prop="hpo_term_name"
                  label="HPO"
                  min-width="120"
                  >

                  <template slot-scope="scope">
                    <span>{{ scope.row.hpo_term_name }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  label="Gene Names"
                  min-width="400">
                  <template slot-scope="scope">
                        <el-tag v-for="one in scope.row.genelist.slice(0,10)" :key="one" size="medium" type="info" class="" @click.native="show_click(one)">{{ one }}</el-tag>
                        <el-tag v-if="scope.row.genelist.length > 10" size="medium" type="warning" class=""> + {{ scope.row.genelist.length - 10 }} others </el-tag>
                  </template>
                </el-table-column>

              </el-table>
            </div>
            <div class="col-sm-4 pagination-info">
              <p class="category">Showing {{from_hpo + 1}} to {{to_hpo}} of {{total_hpo}} entries</p>
            </div>
            <div class="col-sm-12">
              <p-pagination class="pull-right"
                            v-model="pagination_hpo.currentPage"
                            :per-page="pagination_hpo.perPage"
                            :total="pagination_hpo.total"
                            :pager-count="5"

                            >
              </p-pagination>
            </div>
          </div>

        </div>

      </el-dialog>

    </div>




  </div>
</template>

<script>
  import Vue from 'vue';
  import moment from 'moment';
  // import Button from 'src/components/UIComponents/Button';
  import PPagination from 'src/components/UIComponents/Pagination.vue';
  import Loading from 'src/components/Layout/LoadingMainPanel.vue';
  import vars_cufi from 'src/components/Views/Vars_cufi.vue';

  import igv from 'igv';

  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
  import FileSaver from 'file-saver';
  import axios from 'axios';
  import VueUploadComponent from 'vue-upload-component';
  Vue.component('file-upload', VueUploadComponent);

  export default {
    name: 'vars',
    props: {
      global: {
        type: Boolean,
        default: false,
        description: ''
      },
    },
    components: {
      PPagination,
      igv,
      [vars_cufi.name]: vars_cufi,
    },


    data () {
      return {
        selected_vars: [],
        ints_history_data: [],
        ints_partner_data: [],
        freq_type: "",

        show: true,

        formdata_np: {
          name: "",
          np_id_workflow: "",
          created_dt: "",
          created_wh: "",

        },

        formdata_change_class: {
          id_var: 0,
          class_value_a: "",
          class_value_b: "",
          description_a: "",
          description_b: "",
          pane_a: false,
          pane_b: false,
          pane_v: false,
          description_v: "",
        },

        blackbox: "",
        files: [],
        mytab: "change",

        AC_genes_loading: false,

        searchQuery_hpo: "",

        grid_size: {
          "S": 300,
          "M": 400,
          "L": 600,
          "XL": 800,
          "XXL": 1100,
          "3XL": 1500,
        },

        slider_marks: {
          0: '0',
          10: '10',
          20: '20',
          30: '30',
          40: '40',
          50: '50',
          60: '60',
          70: '70',
          80: '80',
          90: '90',
          100: '100',
        },

        slider_marks_5: {
          1: '1',
          2: '2',
          3: '3',
          4: '4',
          5: '5',
        },

        slider_marks_10: {
          0: '0',
          0.1: '.1',
          0.2: '.2',
          0.3: '.3',
          0.4: '.4',
          0.5: '.5',
          0.6: '.6',
          0.7: '.7',
          0.8: '.8',
          0.9: '.9',
          1: '1',
        },

        var_order_options: [
          {
            value: 1,
            label: 'Clinvar DESC'
          },
          {
            value: 3,
            label: 'Internal Class DESC'
          },
          {
            value: 99,
            label: 'id_var'
          },
        ],
        var_order: 1,

        interpret_id_var: 0,
        interpret_value: 0,

        selects: {
          simple: '',
          multiple: 'ARS'
        },

        l_gt: ["0/1", "1/1"],
        l_impacts: ["LOW", "HIGH", "MODERATE", "MODIFIER"],


        l_search: [
          {
            key: "pos",
            value: "POSITION"
          },
          {
            key: "snp",
            value: "dbSNP"
          },

        ],

        s_search: '',
        searchQuery_v: '',
        propsToSearch_v: [],

        pagination_ff: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50, 100],
          total: 0
        },

        tableColumns_ff: [
          {
            prop: 'id_filter',
            label: 'ID',
            minWidth: 50,
            align: "right",
            sortable: false,
          },
          {
            prop: 'name',
            label: 'NAME',
            minWidth: 100,
            align: "left",
            sortable: false,
          },
          {
            prop: 'short_text',
            label: 'SHORT',
            minWidth: 100,
            align: "left",
            sortable: false,
          },
          {
            prop: 'status',
            label: 'S.',
            minWidth: 40,
            align: "center",
            sortable: false,
          },
          {
            prop: 'made',
            label: 'MADE',
            minWidth: 80,
            align: "center",
            sortable: false,
          },

        ],

        pagination_hpo: {
          perPage: 5,
          currentPage: 1,
          perPageOptions: [5,10],
          total: 0
        },


        tableColumns_transcripts: [
          {
            prop: 'a_4',
            label: 'Gene',
            minWidth: 130,
            align: "center",
            sortable: false,
          },
          {
            prop: 'a_7',
            label: 'Feature',
            minWidth: 130,
            align: "center",
            sortable: false,
          },
          {
            prop: 'a_11',
            label: 'HGVSc',
            minWidth: 120,
            align: "center",
            sortable: false,
          },
          {
            prop: 'a_12',
            label: 'HGVSp',
            minWidth: 120,
            align: "center",
            sortable: false,
          },
          {
            prop: 'a_9',
            label: 'Exon',
            minWidth: 60,
            align: "center",
            sortable: false,
          },
          {
            prop: 'a_10',
            label: 'Intron',
            minWidth: 60,
            align: "center",
            sortable: false,
          },
          {
            prop: 'a_3',
            label: 'Impact',
            minWidth: 100,
            align: "center",
            sortable: false,
          },
          {
            prop: 'a_2',
            label: 'Conseq',
            minWidth: 160,
            align: "center",
            sortable: false,
          },
          {
            prop: 'a_13',
            label: 'cDNA',
            minWidth: 100,
            align: "center",
            sortable: false,
          },
          {
            prop: 'a_14',
            label: 'CDS',
            minWidth: 100,
            align: "center",
            sortable: false,
          },
          {
            prop: 'a_15',
            label: 'Protein',
            minWidth: 100,
            align: "center",
            sortable: false,
          },
          {
            prop: 'a_16',
            label: 'Amino acids',
            minWidth: 80,
            align: "center",
            sortable: false,
          },
          {
            prop: 'a_17',
            label: 'Codons',
            minWidth: 80,
            align: "center",
            sortable: false,
          },
          {
            prop: 'a_19',
            label: 'Distance',
            minWidth: 80,
            align: "center",
            sortable: false,
          },
          {
            prop: 'er',
            label: 'ER',
            minWidth: 80,
            align: "center",
            sortable: false,
          },
        ],

      }
    },

    computed: {
      ...mapState({
        vars: state => state.vars,
        f_filters: state => state.f_filters,
        u_filters: state => state.u_filters,
        hpo: state => state.hpo,
        l_gene: state => state.l_gene,
        l_consequences: state => state.l_consequences,
        l_features: state => state.l_features,
        l_clinvars: state => state.l_clinvars,
        l_clinvar_dns: state => state.l_clinvar_dns,
        l_iclasses: state => state.l_iclasses,
        d_acmg_sigs: state => state.d_acmg_sigs,
        d_a_cols: state => state.d_a_cols,
        d_filter: state => state.d_filter,

        cufi: state => state.cufi,
        cufi_yes: state => state.cufi_yes,
        id_project: state => state.id_project,
        id_workflow: state => state.id_workflow,
        id_examination: state => state.id_examination,
        selected_exams: state => state.selected_exams,
        pagination_v: state => state.pagination_v,
        sorting_v: state => state.sorting_v,
        loading_v: state => state.loading_v,

        vars_order: state => state.vars_order,
        vars_selected: state => state.vars_selected,
        user_setup: state => state.user_setup,

        pan_new_project: state => state.pan_new_project,
        pan_project: state => state.pan_project,

        transcripts: state => state.transcripts,
        igv_on: state => state.igv_on,

        token: state => state.token,
        user: state => state.user,
        id_biorole: state => state.id_biorole,
        subject: state => state.subject,
        application: state => state.application,
        app: state => state.app,
        rapp: state => state.rapp,
        app_version: state => state.app_version,
        modal_acmg: state => state.modal_acmg,
        acmg_data: state => state.acmg_data,
        polyt_data: state => state.polyt_data,
        modal_freq: state => state.modal_freq,
        freq_data: state => state.freq_data,

        vars_tab_name: state => state.vars_tab_name,


      }),

      ...mapGetters({
        g_gene: 'g_gene',
        g_examination: 'g_examination',
        g_examination_export: 'g_examination_export',
        g_examination_selected: 'g_examination_selected',
        g_project: 'g_project',
        g_iclasses: 'g_iclasses',
        g_iclasses_style: 'g_iclasses_style',

        }),

      pagedData_v () {
        return this.vars.slice(this.from_v, this.to_v)
      },

      vars_mydata () {
        if (!this.searchQuery_v) {
          return this.pagedData_v
        }
        let result = this.vars
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch_v) {
              let rowValue = row[key].toString()
              if (rowValue.includes && rowValue.includes(this.searchQuery_v)) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.pagination_v.total = result.length
        return result.slice(this.from_v, this.to_v)
      },


      vars_data () {
        if (!this.searchQuery_v) {
          this.pagination_v.total = this.vars.length
          return this.pagedData_v
        }
        let result = this.vars
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch_v) {
              let rowValue = row[key].toString()
              if (rowValue.includes && rowValue.includes(this.searchQuery_v)) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.pagination_v.total = result.length
        return result.slice(this.from_v, this.to_v)
      },


      to_v () {
        let highBound = this.from_v + this.pagination_v.perPage
        if (this.total_v < highBound) {
          highBound = this.total_v
        }
        return highBound
      },

      from_v () {
        return this.pagination_v.perPage * (this.pagination_v.currentPage - 1)
      },

      total_v () {
        this.pagination_v.total = this.vars.length
        return this.vars.length
      },


      pagedData_ff () {
        return this.f_filters.slice(this.from_ff, this.to_ff)
      },

      f_filters_data () {
        if (!this.searchQuery_ff) {
          this.pagination_ff.total = this.f_filters.length
          return this.pagedData_ff
        }
        let result = this.f_filters
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch_ff) {
              let rowValue = row[key].toString()
              if (rowValue.includes && rowValue.includes(this.searchQuery_ff)) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.pagination_ff.total = result.length
        return result.slice(this.from_ff, this.to_ff)
      },

      to_ff () {
        let highBound = this.from_ff + this.pagination_ff.perPage
        if (this.total_ff < highBound) {
          highBound = this.total_ff
        }
        return highBound
      },

      from_ff () {
        return this.pagination_ff.perPage * (this.pagination_ff.currentPage - 1)
      },

      total_ff () {
        this.pagination_ff.total = this.f_filters.length
        return this.f_filters.length
      },

      pagedData_hpo () {
        return this.hpo.slice(this.from_hpo, this.to_hpo)
      },

      hpo_data () {
        if (!this.searchQuery_hpo) {
          this.pagination_hpo.total = this.hpo.length
          return this.pagedData_hpo
        }
        let result = this.hpo
          .filter((row) => {
            let isIncluded = false
            let rowValue = row["hpo_term_name"].toString()
            if (rowValue.includes && rowValue.includes(this.searchQuery_hpo)) {
              isIncluded = true
            }
            return isIncluded
          })
        this.pagination_hpo.total = result.length
        return result.slice(this.from_hpo, this.to_hpo)
      },

      to_hpo () {
        let highBound = this.from_hpo + this.pagination_hpo.perPage
        if (this.total_hpo < highBound) {
          highBound = this.total_hpo
        }
        return highBound
      },

      from_hpo () {
        return this.pagination_hpo.perPage * (this.pagination_hpo.currentPage - 1)
      },

      total_hpo () {
        this.pagination_hpo.total = this.hpo.length
        return this.hpo.length
      },


      slider_value: {
        get () { return this.$store.state.slider_value; },
        set (value) { this.$store.commit('m_slider_value', value); }
      },


      filter_name: {
        get () { return this.$store.state.ff_name; },
        set (value) { this.$store.commit('m_ff_name', value); }
      },

      filter_description: {
        get () { return this.$store.state.ff_description; },
        set (value) { this.$store.commit('m_ff_description', value); }
      },

      search_vars_by_select: {
        get () { return this.$store.state.search_vars_by_select; },
        set (value) { this.$store.commit('m_search_vars_by_select', value); }
      },

      search_vars_by_value: {
        get () { return this.$store.state.search_vars_by_value; },
        set (value) { this.$store.commit('m_search_vars_by_value', value); }
      },

      igv_switch: {
        get () { return this.$store.state.igv_switch; },
        set (value) { this.$store.commit('m_igv_switch', value); }
      },

      fastras_freq: {
        get () { return this.$store.state.fastras_freq; },
        set (value) { this.$store.commit('m_fastras_freq', value); }
      },

      var_page: {
        get () { return this.$store.state.var_page; },
        set (value) { this.$store.commit('m_var_page', value); }
      },

      vars_selected_tab: {
        get () { return this.$store.state.vars_selected_tab; },
        set (value) { this.$store.commit('m_vars_selected_tab', value); }
      },

      modal_interpretation: {
        get () { return this.$store.state.modal_interpretation; },
        set (value) { this.$store.commit('m_modal_interpretation', value); }
      },

      modal_transcripts: {
        get () { return this.$store.state.modal_transcripts; },
        set (value) { this.$store.commit('m_modal_transcripts', value); }
      },

      modal_pseudopanels: {
        get () { return this.$store.state.modal_pseudopanels; },
        set (value) { this.$store.commit('m_modal_pseudopanels', value); }
      },

      modal_acmg: {
        get() { return this.$store.state.modal_acmg; },
        set(value) { this.$store.commit('m_modal_acmg', value); }
      },

      modal_freq: {
        get() { return this.$store.state.modal_freq; },
        set(value) { this.$store.commit('m_modal_freq', value); }
      },

      modal_hpo_terms: {
        get() { return this.$store.state.modal_hpo_terms; },
        set(value) { this.$store.commit('m_modal_hpo_terms', value); }
      },

    },


    methods: {
      ...mapMutations({
        m_slider_value: 'm_slider_value',
        m_igv_switch: 'm_igv_switch',
        m_igv_on: 'm_igv_on',
        m_var_page: 'm_var_page',
        m_ss_gene: 'm_ss_gene',
        m_vars: 'm_vars',
        m_vars_selected_tab: 'm_vars_selected_tab',
        m_vars_order: 'm_vars_order',
        m_vars_selected: 'm_vars_selected',
        m_id_examination: 'm_id_examination',
        m_modal_acmg: 'm_modal_acmg',
        m_modal_freq: 'm_modal_freq',
        m_cufi_selected: 'm_cufi_selected',
        m_cufi_load: 'm_cufi_load',

        m_modal_interpretation: 'm_modal_interpretation',
        m_modal_transcripts: 'm_modal_transcripts',
        m_transcripts: 'm_transcripts',
        m_fastras_freq: 'm_fastras_freq',

        m_vars_tab_name: 'm_vars_tab_name',

        m_d_cufis: 'm_d_cufis',
        m_l_cufis: 'm_l_cufis',

      }),


      ...mapActions({
        a_varsall: 'a_varsall',
        a_vars: 'a_vars',
        a_f_filters: 'a_f_filters',
        a_filter_save: 'a_filter_save',
        a_change_interpretation: 'a_change_interpretation',
        a_anots: 'a_anots',
        a_notify: 'a_notify',
        a_examinations: 'a_examinations',
        a_examinations_refresh: 'a_examinations_refresh',
        a_acmg_data: 'a_acmg_data',
        a_freq_data: 'a_freq_data',
        a_hpo_get: 'a_hpo_get',
      }),


      handle_unavailable (index, row, event) {
        event.stopImmediatePropagation();
        alert(`Not available in trial version.`)
      },

      handleEdit (index, row) {
        alert(`Your want to edit ${row.name}`)
      },

      handleDelete (index, row) {
        let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1)
        }
      },

      tableRowClassName({row, rowIndex}) {
        if (row.er === 1) {
          return 'success-row';
        } else if (row.er === -1) {
          return 'danger-row';
        } else {
          return 'row_0';
        }
      },


      tableRowClassNameVars({row, rowIndex}) {
        // if (row.var_status === 2) {
        //   return 'info-row';
        // } else if (row.var_status === 1) {
        //   return 'row_0';
        // } else {
        //   return 'row_0';
        // }
        return 'row_0';
      },


      canSelectRow() {

      },


      submit_np() {

      },

      handle_transcripts( index, row, event ) {
        event.stopImmediatePropagation();
        this.m_transcripts([]);
        this.m_modal_transcripts(true);
        this.a_anots( {"id_var": row.id_var } );
        // resolve(this.a_anots( {"id_var": tree.id_var, "er": this.cufi.er_switch } ) );
        // console.log(this.a_anots( {"id_var": tree.id_var, "er": this.cufi.er_switch } ) );
        // resolve(this.a_anots( {"id_var": tree.id_var, "er": this.cufi.er_switch } ) );
        // let mypayload = { "id_var": tree.id_var, "er": this.cufi.er_switch };
      },



      async handle_igv( event ) {
        if (this.igv_switch) {
          axios.get(process.env.VUE_APP_BE + '/get_igv_id', { withCredentials: true, params: { "id_examination": this.id_examination  }, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8', }, })
              .then(response => {
                let bam_url = process.env.VUE_APP_BE + "/get_bam/" + this.subject.id_subject + "/" + response.data["igv_bam"] + ".bam";
                let bai_url = process.env.VUE_APP_BE + "/get_bai/" + this.subject.id_subject + "/" + response.data["igv_bai"] + ".bam.bai";
                let bam_name = response.data["bam_name"];
                let bed_path = response.data["bed_path"];

                // let bam_url = process.env.VUE_APP_STATIC + "static/278-21B_S12_so_rmdp.bam";
                // let bai_url = process.env.VUE_APP_STATIC + "static/278-21B_S12_so_rmdp.bam.bai";


                var igvDiv = document.getElementById("igv-div");
                var options = {
                    showNavigation: true,
                    showRuler: true,
                    supportQueryParameters: true,
                    reference: {
                      id: "hg38",
                      name: "Human (GRCh38/hg38)",
                      fastaURL: "https://s3.amazonaws.com/igv.broadinstitute.org/genomes/seq/hg38/hg38.fa",
                      indexURL: "https://s3.amazonaws.com/igv.broadinstitute.org/genomes/seq/hg38/hg38.fa.fai",
                      cytobandURL: "https://s3.amazonaws.com/igv.org.genomes/hg38/annotations/cytoBandIdeo.txt.gz",
                      aliasURL: "https://s3.amazonaws.com/igv.org.genomes/hg38/hg38_alias.tab"
                    },
                    locus: "all",
                    loadDefaultGenomes: false,
                    tracks: [
                      {
                        type: "alignment",
                        name: bam_name,
                        format: "bam",
                        url: bam_url,
                        indexURL: bai_url,
                        alignmentRowHeight: 12,
                        samplingWindowSize: 200,
                        height: 500,
                      },

                      // {
                      //   type: "wig",
                      //   name: "CTCF",
                      //   url: "https://www.encodeproject.org/files/ENCFF356YES/@@download/ENCFF356YES.bigWig",
                      //   min: "0",
                      //   max: "30",
                      //   color: "rgb(0, 0, 150)",
                      //   guideLines: [
                      //   {color: 'green', dotted: true, y: 25},
                      //   {color: 'red', dotted: false, y: 5}
                      //   ]
                      // },

                      // {
                      //   type: 'mut',
                      //   format: 'maf',
                      //   url: 'https://s3.amazonaws.com/igv.org.demo/TCGA.BRCA.mutect.995c0111-d90b-4140-bee7-3845436c3b42.DR-10.0.somatic.maf.gz',
                      //   indexed: false,
                      //   height: 700,
                      //   displayMode: "EXPANDED",
                      // },

                      {
                        name: "Refseq Genes",
                        url: "https://s3.amazonaws.com/igv.org.genomes/hg38/refGene.sorted.txt.gz",
                        indexURL: "https://s3.amazonaws.com/igv.org.genomes/hg38/refGene.sorted.txt.gz.tbi",
                        order: 1000000,
                        indexed: false,
                        height: 100,
                        displayMode: "collapse",

                      },

                      // {
                      //   type: "variant",
                      //   format: "vcf",
                      //   url: "https://devel.variantanalyzer.eu/tracks/clinvar_20230218_pathogenic.vcf.gz",
                      //   indexURL: "https://devel.variantanalyzer.eu/tracks/clinvar_20230218_pathogenic.vcf.gz.tbi",
                      //   name: "1KG variants",
                      //   squishedCallHeight: 1,
                      //   expandedCallHeight: 4,
                      //   displayMode: "squished",
                      //   visibilityWindow: 1000
                      // },

                      {
                        type: "variant",
                        format: "vcf",
                        url: process.env.VUE_APP_STATIC + "static/clinvar.vcf.gz",
                        indexURL: process.env.VUE_APP_STATIC + "static/clinvar.vcf.gz.tbi",
                        name: "ClinVar",
                        squishedCallHeight: 1,
                        expandedCallHeight: 4,
                        displayMode: "squished",
                        visibilityWindow: 1000
                      },

                      {
                        type: "annotation",
                        format: "bed",
                        url: process.env.VUE_APP_STATIC + bed_path,
                        name: "BED",
                        squishedCallHeight: 1,
                        expandedCallHeight: 4,
                        displayMode: "squished",
                        visibilityWindow: 1000
                      }

                    ]
                };

                igv.createBrowser(igvDiv, options)
                        .then(function (browser) {
                            igv.browser = browser;

                        });

                // igv.loadTrack({
                //     url: bam_url,
                //     indexURL: bai_url,
                //     name: '20S1'
                //     })

              }, error => {
                  console.log(error);
              });

        } else {
          igv.removeBrowser(browser);
        }


        // event.stopImmediatePropagation();
        // this.m_transcripts([]);
        // this.m_modal_transcripts(true);
        // this.a_anots( {"id_var": row.id_var, "er": this.er_switch } );
        // resolve(this.a_anots( {"id_var": tree.id_var, "er": this.cufi.er_switch } ) );
        // console.log(this.a_anots( {"id_var": tree.id_var, "er": this.cufi.er_switch } ) );
        // resolve(this.a_anots( {"id_var": tree.id_var, "er": this.cufi.er_switch } ) );
        // let mypayload = { "id_var": tree.id_var, "er": this.cufi.er_switch };

      },


      igv_search(row, event) {
        let sest = row.chrom + ":" + row.pos;
        igv.browser.search(sest);
      },


      submit_formdata_change_class() {
        this.a_change_interpretation( this.formdata_change_class );
        // if (this.cufi.cufi_yes) {
        //   this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": this.pagination_v.currentPage, "totalPages": this.pagination_v.total, "er_switch": this.er_switch, "fastras_freq": this.fastras_freq, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "cufi": this.cufi, "id_examination": this.id_examination, "selected_exams": this.selected_exams, "id_project": this.id_project, "selected": this.vars_selected } );
        // } else {
        //   this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": this.pagination_v.currentPage, "totalPages": this.pagination_v.total, "er_switch": this.er_switch, "fastras_freq": this.fastras_freq, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "cufi": this.cufi, "id_examination": this.id_examination, "selected_exams": this.selected_exams, "id_project": this.id_project, "selected": this.vars_selected } );
        // }
        // this.m_modal_interpretation(false);
        // this.a_notify( {message: "Interpretation has been changed."} );
      },


      iclass_tabs(tab, event) {
        if (tab.name == "change") {

        } else if (tab.name == "vardescription") {
          this.formdata_change_class.pane_v = true;
          axios.get(process.env.VUE_APP_BE + '/change_interpretation', { withCredentials: true, params: { "id_var": this.formdata_change_class.id_var, what: "desc" }, headers: { 'Authorization': this.token, 'RouterApp': this.rapp, 'Content-Type': 'application/json;charset=UTF-8', }})
              .then(response => {
                this.formdata_change_class.description_v = response.data["description_v"]
              }, error => {
                  console.log(error);
              });

        } else if (tab.name == "history") {
          this.formdata_change_class.pane_v = false;
          axios.get(process.env.VUE_APP_BE + '/change_interpretation', { withCredentials: true, params: { "id_var": this.formdata_change_class.id_var, what: "hist" }, headers: { 'Authorization': this.token, 'RouterApp': this.rapp, 'Content-Type': 'application/json;charset=UTF-8', }})
              .then(response => {
                this.ints_history_data = response.data["ints_history"]
              }, error => {
                  console.log(error);
              });
        } else if (tab.name == "partners") {
          this.formdata_change_class.pane_v = false;
          axios.get(process.env.VUE_APP_BE + '/change_interpretation', { withCredentials: true, params: { "id_var": this.formdata_change_class.id_var, what: "part" }, headers: { 'Authorization': this.token, 'RouterApp': this.rapp, 'Content-Type': 'application/json;charset=UTF-8', }})
              .then(response => {
                this.ints_partner_data = response.data["ints_partner"]
              }, error => {
                  console.log(error);
              });

        }
        else {
          this.formdata_change_class.pane_v = false;
        }
      },


      show_slider_value() {
        this.a_notify( {message: "Genes were added to Custom Filter"} );
      },


      change_var_page() {

      },


      order_select_change(value) {
        if (this.global) {
          this.m_vars_order(value);
          this.a_vars( { "order": value } );
        } else {
          this.m_vars_order(value);
          this.a_vars( { "order": value, "cufi": this.cufi, "id_examination": this.id_examination, "id_project": this.id_project, "selected": this.vars_selected } );
        }
      },


      show_tab_click(tab, event){
        this.m_vars_tab_name(tab.name);
        console.log(tab.name);

        this.pagination_v.currentPage = 1;
        if (tab.name === "x") {
          this.m_cufi_selected( 1 );
          this.m_cufi_load();
          this.m_vars_selected(null);
          this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "selected": this.vars_selected } );
        } else if (tab.name === "b") {
          axios.get(process.env.VUE_APP_BE + '/cufi', { withCredentials: true, params: {id_workflow: this.id_workflow}, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8' } })
            .then(response => {
              if (response.data["id_cufi_default"] > 0) {
                this.m_cufi_selected( response.data["id_cufi_default"] );
                this.m_cufi_load();
                this.m_vars_selected(null);
                this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "cufi": this.cufi, "selected": this.vars_selected } );
              } else {
                this.m_cufi_selected( 1 );
                this.m_cufi_load();
                this.m_vars_selected(null);
                this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "cufi": this.cufi, "selected": this.vars_selected } );
              }
            }, error => {
              console.log(error);
            });
        } else if (tab.name === "p") {
          this.m_cufi_selected( 1 );
          this.m_cufi_load();
          this.m_vars_selected(false);
          this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "cufi": this.cufi, "selected": this.vars_selected } );
        } else if (tab.name === "s") {
          this.m_cufi_selected( 1 );
          this.m_cufi_load();
          this.m_vars_selected(true);
          this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "cufi": this.cufi, "selected": this.vars_selected } );
        } else if (tab.name === "t") {

        } else if (tab.name === "d") {
          this.a_hpo_get();

        } else if (tab.name === "c") {
          axios.get(process.env.VUE_APP_BE + '/cufi', { withCredentials: true, params: {id_workflow: this.id_workflow}, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8' } })
            .then(response => {
              if (response.data["id_cufi_default"] > 0) {
                this.m_cufi_selected( response.data["id_cufi_default"] );
                this.m_cufi_load();
                this.m_vars_selected(null);
                this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "cufi": this.cufi, "selected": this.vars_selected } );
              } else {
                this.m_cufi_selected( 1 );
                this.m_cufi_load();
                this.m_vars_selected(null);
                this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "cufi": this.cufi, "selected": this.vars_selected } );

              }

            }, error => {
              console.log(error);
            });

        } else {
          // axios.get(process.env.VUE_APP_BE + '/cufi', { withCredentials: true, params: {id_workflow: this.id_workflow}, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8' } })
          //   .then(response => {
          //     this.m_cufi_selected( response.data["id_cufi_default"] );
          //     this.m_cufi_load();
          //     // this.show_vars();

          //     this.m_cufi_yes(true);
          //     this.cufi.cufi_yes = true;
          //     this.m_vars_selected(null);
          //     this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "cufi": this.cufi, "selected": this.vars_selected } );

          //   }, error => {
          //     console.log(error);
          //   });
        }
        // console.log(this.cufi_yes);
      },


      add_project(){
        this.m_pan_new_project(true);
        this.m_id_project(0);
      },


      show_click(value){

      },


      select_filter_gene(value){
        this.m_ss_gene(value);
      },


      select_filter(value){
        console.log(value);
      },


      show_vars(){
        this.pagination_v.currentPage = 1;
        this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "fastras_freq": this.fastras_freq, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "cufi": this.cufi, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "selected": this.vars_selected  } );
      },


      export_all_vars(event){
        event.stopImmediatePropagation();
        this.a_vars( { "export": 2, "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "fastras_freq": this.fastras_freq, "id_examination": this.id_examination, "id_project": this.id_project, "sample_id": this.g_examination_export.sample_id,  "selected_exams": this.selected_exams, "selected": this.vars_selected } );
      },


      export_grid_vars(event){
        event.stopImmediatePropagation();

        if (["b", "c"].includes(this.vars_selected_tab)) {
          this.a_vars( { "export": 1, "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "fastras_freq": this.fastras_freq, "id_examination": this.id_examination, "id_project": this.id_project, "sample_id": this.g_examination_export.sample_id,  "selected_exams": this.selected_exams, "cufi": this.cufi, "selected": this.vars_selected } );
        } else {
          this.a_vars( { "export": 1, "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "fastras_freq": this.fastras_freq, "id_examination": this.id_examination, "id_project": this.id_project, "sample_id": this.g_examination_export.sample_id,  "selected_exams": this.selected_exams, "selected": this.vars_selected } );
        }
      },


      cufi_genes_del(){
          let mykey = "genes";
          let value = [];
          this.$store.commit('m_cufi', {mykey, value});
      },


      mylink_symbol(myvar) {
        return "https://www.genecards.org/cgi-bin/carddisp.pl?gene=" + myvar ;
      },


      mylink_clinsig(row) {
        return "http://www.ncbi.nlm.nih.gov/clinvar/?term=" + row.a_879 + "[alleleid]" ;
      },


      mylink_dbsnp(one) {
        return "http://www.ncbi.nlm.nih.gov/snp/" + one ;
      },

      mylink_varsome(one) {
        return "https://varsome.com/variant/hg38/" + one ;
      },

      mylink_genebe(row) {
        return `https://genebe.net/variant/hg38/${row.chrom}:${row.pos}-${row.ref}>${row.alt}`;
      },

      mylink_pos(row) {
        return "http://www.ensembl.org/Homo_sapiens/Location/View?r=" + (row.chrom).substring(3) + ":" + row.pos + "-" + row.pos ;
      },


      mylink_hgvsc(row) {
        return "http://genome.ucsc.edu/cgi-bin/hgTracks?db=hg38&position=" + row.a_11_full + "&hgt.out3=submit" ;
      },


      mylink_transcript(row) {
        return "https://www.ncbi.nlm.nih.gov/nuccore/" + row.a_7 ;
      },


      mylink_cosmic(row) {
        return "https://cancer.sanger.ac.uk/cosmic/search?q=" + row.a_501 ;
      },


      alertuj(mytext) {
        if (mytext === 1) {
          alert(mytext + " - Benign");
        } else if (mytext === 2) {
          alert(mytext + " - Likely benign");
        } else if (mytext === 3) {
          alert(mytext + " - Uncertain significance");
        } else if (mytext === 4) {
          alert(mytext + " - Likely pathogenic");
        } else if (mytext === 5) {
          alert(mytext + " - Pathogenic");
        } else {
          alert(mytext);
        }
      },


      x_modal_interpretation ( id_var, myvalue, mytype, descr_v ) {
        if ( mytype == "a") {
          this.mytab = "change_a";
          this.formdata_change_class.class_value_a = myvalue;
          this.formdata_change_class.description_a = "";
          this.formdata_change_class.pane_a = true;
          this.formdata_change_class.pane_b = false;
          this.formdata_change_class.pane_v = false;
        } else if ( mytype == "b") {
          this.mytab = "change_b";
          this.formdata_change_class.class_value_b = myvalue;
          this.formdata_change_class.description_b = "";
          this.formdata_change_class.pane_a = false;
          this.formdata_change_class.pane_b = true;
          this.formdata_change_class.pane_v = false;
        }

        this.ints_history_data = [];
        this.ints_partner_data = [];
        this.formdata_change_class.id_var = id_var;
        this.formdata_change_class.description_v = descr_v;
        this.m_modal_interpretation(true);
      },


      popover_items ( mydata ) {
        let myitems = []
        for (let i of mydata.split(',')) {
          myitems.push( i.replace(/_/g, " ") );
        }
        return "<ul><li>" + myitems.join("</li><li>") + "</li></ul>";
      },


      search_vars_by( event ) {
        event.stopImmediatePropagation();
        if (this.search_vars_by_select === "") {
          this.$alert('Select item for filtering first.', 'WRONG.', {
            confirmButtonText: 'OK',
          });
        } else {
          this.pagination_v.currentPage = 1;

          if (this.vars_selected_tab === "x") {
            this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "fastras_freq": this.fastras_freq, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "cufi": this.cufi, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "selected": this.vars_selected } );
          } else if (this.vars_selected_tab === "b") {
            this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "fastras_freq": this.fastras_freq, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "cufi": this.cufi, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "cufi": this.cufi, "selected": this.vars_selected } );
          } else if (this.vars_selected_tab === "c") {
            this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "fastras_freq": this.fastras_freq, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "cufi": this.cufi, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "cufi": this.cufi, "selected": this.vars_selected } );
          } else if (this.vars_selected_tab === "p") {
            this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "fastras_freq": this.fastras_freq, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "cufi": this.cufi, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "selected": this.vars_selected } );
          } else if (this.vars_selected_tab === "s") {
            this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "fastras_freq": this.fastras_freq, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "cufi": this.cufi, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "selected": this.vars_selected } );
          } else {
            this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "fastras_freq": this.fastras_freq, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "cufi": this.cufi, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "selected": this.vars_selected } );
          }

        }
      },


      search_vars_all( event ) {
        event.stopImmediatePropagation();
        this.search_vars_by_select = "";
        this.search_vars_by_value = "";

        if (this.vars_selected_tab === "x") {
          this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "fastras_freq": this.fastras_freq, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "selected": this.vars_selected } );
        } else if (this.vars_selected_tab === "b") {
          this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "fastras_freq": this.fastras_freq, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "cufi": this.cufi, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "selected": this.vars_selected } );
        } else if (this.vars_selected_tab === "c") {
          this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "fastras_freq": this.fastras_freq, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "cufi": this.cufi, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "selected": this.vars_selected } );
        } else {
          this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "fastras_freq": this.fastras_freq, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "cufi": this.cufi, "id_examination": this.id_examination, "selected_exams": this.selected_exams, "id_project": this.id_project, "selected": this.vars_selected } );
        }

      },


      sign_out_vars (event) {
        event.stopImmediatePropagation();
        var sel_vars = [];
        this.selected_vars.forEach(element => sel_vars.push(element.id_ant));

        if (this.selected_vars.length > 0) {
          axios.post(process.env.VUE_APP_BE + '/sign_out_vars', { "id_project": this.id_project, "id_examination": this.id_examination, "selected_vars": this.selected_vars }, { withCredentials: true, headers: { 'Authorization': this.token, 'RouterApp': this.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
              .then(response => {
                if (response.data.resp_info > "") {
                  this.$alert('Variants were already signed out. Please first reset sign out to change.', 'Attention', {
                    confirmButtonText: 'OK',
                    type: "danger"
                  });
                } else {
                  this.$notify({
                    title: 'INFO',
                    message: 'Variants signed out',
                    type: 'success',
                    position: 'top-right',
                  });
                }

                this.a_examinations_refresh( {"id_project": this.id_project} );

                if (["b", "c"].includes(this.vars_selected_tab)) {
                  this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "fastras_freq": this.fastras_freq, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "cufi": this.cufi, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "selected": this.vars_selected } );
                } else {
                  this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "fastras_freq": this.fastras_freq, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "selected": this.vars_selected } );
                }

              }, error => {
                  console.log(error);
              });
        } else {
          this.$alert('No variant(s) selected to be signed out.', 'Warning', {
                    confirmButtonText: 'OK',
                    type: "warning"
                  });
        }

      },


      sign_out_vars_reset (event) {
        event.stopImmediatePropagation();

        axios.put(process.env.VUE_APP_BE + '/sign_out_vars', { "id_project": this.id_project, "id_examination": this.id_examination }, { withCredentials: true, headers: { 'Authorization': this.token, 'RouterApp': this.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
            .then(response => {
              this.$notify({
                title: 'INFO',
                message: 'Successful variant reset',
                type: 'success',
                position: 'top-right',
              });

              this.a_examinations_refresh( {"id_project": this.id_project} );

              if (["b", "c"].includes(this.vars_selected_tab)) {
                this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "fastras_freq": this.fastras_freq, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "cufi": this.cufi, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "selected": this.vars_selected } );
              } else {
                this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "fastras_freq": this.fastras_freq, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "selected": this.vars_selected } );
              }

            }, error => {
                console.log(error);
            });
      },


      sign_out_no_vars (event) {
        event.stopImmediatePropagation();

        axios.patch(process.env.VUE_APP_BE + '/sign_out_vars', { "id_project": this.id_project, "id_examination": this.id_examination }, { withCredentials: true, headers: { 'Authorization': this.token, 'RouterApp': this.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
            .then(response => {
              this.$notify({
                title: 'INFO',
                message: 'Sign-out without variants was successful',
                type: 'info',
                position: 'top-right',
              });

              this.a_examinations_refresh( {"id_project": this.id_project} );
            }, error => {
                console.log(error);
            });

      },


      preselect_vars (event) {
        event.stopImmediatePropagation();
        var sel_vars = [];
        this.selected_vars.forEach(element => sel_vars.push(element.id_ant));

        if (this.selected_vars.length > 0) {
          axios.post(process.env.VUE_APP_BE + '/preselect_vars', { "id_project": this.id_project, "id_examination": this.id_examination, "selected_vars": this.selected_vars }, { withCredentials: true, headers: { 'Authorization': this.token, 'RouterApp': this.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
              .then(response => {
                if (response.data.resp_info > "") {
                  this.$alert('Variants were already signed out. Please first reset sign out to change.', 'Attention', {
                    confirmButtonText: 'OK',
                    type: "danger"
                  });
                } else {
                  this.$notify({
                    title: 'INFO',
                    message: 'Variants are moved to Preselected',
                    type: 'success',
                    position: 'top-right',
                  });
                }

                if (this.vars_selected_tab === "x") {
                  this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "fastras_freq": this.fastras_freq, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "selected": this.vars_selected } );
                } else if (this.vars_selected_tab === "b") {
                  this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "fastras_freq": this.fastras_freq, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "cufi": this.cufi, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "selected": this.vars_selected } );
                } else if (this.vars_selected_tab === "c") {
                  this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "fastras_freq": this.fastras_freq, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "cufi": this.cufi, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "selected": this.vars_selected } );
                } else {
                  this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "fastras_freq": this.fastras_freq, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "cufi": this.cufi, "id_examination": this.id_examination, "selected_exams": this.selected_exams, "id_project": this.id_project, "selected": this.vars_selected } );
                }

              }, error => {
                  console.log(error);
              });
        } else {
          this.$alert('No variants are selected for the Preselect.', 'Warning', {
                    confirmButtonText: 'OK',
                    type: "warning"
                  });
        }

      },


      unpreselect_vars (event) {
        event.stopImmediatePropagation();
        var sel_vars = [];
        this.selected_vars.forEach(element => sel_vars.push(element.id_ant));

        if (this.selected_vars.length > 0) {
          axios.put(process.env.VUE_APP_BE + '/preselect_vars', { "id_project": this.id_project, "id_examination": this.id_examination, "selected_vars": this.selected_vars }, { withCredentials: true, headers: { 'Authorization': this.token, 'RouterApp': this.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
              .then(response => {
                if (response.data.resp_info > "") {
                  this.$alert('Variants were already signed out. Please first reset sign out to change.', 'Attention', {
                    confirmButtonText: 'OK',
                    type: "danger"
                  });
                } else {
                  this.$notify({
                    title: 'INFO',
                    message: 'Variants are removed from Preselected',
                    type: 'success',
                    position: 'top-right',
                  });
                }

                if (this.vars_selected_tab === "x") {
                  // this.cufi.cufi_yes = false;
                  // this.cufi.full_yes = true;
                  this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "fastras_freq": this.fastras_freq, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "selected": this.vars_selected } );
                } else if (this.vars_selected_tab === "b") {
                  // this.cufi.cufi_yes = true;
                  // this.cufi.full_yes = true;
                  this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "fastras_freq": this.fastras_freq, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "cufi": this.cufi, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "selected": this.vars_selected } );
                } else if (this.vars_selected_tab === "c") {
                  // this.cufi.cufi_yes = true;
                  // this.cufi.full_yes = true;
                  this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "fastras_freq": this.fastras_freq, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "cufi": this.cufi, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "selected": this.vars_selected } );
                } else {
                  // this.cufi.cufi_yes = false;
                  // this.cufi.full_yes = true;
                  this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "fastras_freq": this.fastras_freq, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "cufi": this.cufi, "id_examination": this.id_examination, "selected_exams": this.selected_exams, "id_project": this.id_project, "selected": this.vars_selected } );
                }

              }, error => {
                  console.log(error);
              });
        } else {
          this.$alert('No variants are selected for the Preselect.', 'Warning', {
                    confirmButtonText: 'OK',
                    type: "warning"
                  });
        }

      },


      old_make_report_vars (event, sample_id) {
        event.stopImmediatePropagation();
        var sel_vars = [];
        var my_filename = sample_id.replace(" ", "_") + ".pdf"
        this.selected_vars.forEach(element => sel_vars.push(element.id_ant));

        if (this.selected_vars.length > 0) {
          axios.post(process.env.VUE_APP_BE + '/make_report_vars', { id_project: this.id_project, "id_examination": this.id_examination, "selected_vars": this.selected_vars, "selected_exams": this.selected_exams, "id_project": this.id_project }, { withCredentials: true, headers: { 'Authorization': this.token, 'Content-Type': 'text/html', }, responseType: 'blob', })
              .then(response => {
                      var blob = new Blob([response.data]);
                      FileSaver.saveAs(blob, my_filename);
              }, error => {
                  console.log(error);
              });
        } else {
          this.$alert('No variants are selected for the report.', 'Warning', {
                    confirmButtonText: 'OK',
                    type: "warning"
                  });
        }


      },


      make_report_vars (event, sample_id) {
        event.stopImmediatePropagation();
        var my_filename = sample_id.replace(" ", "_").replace("-", "_") + "_report.pdf"
        axios.post(process.env.VUE_APP_BE + '/make_report_vars', { "id_examination": this.id_examination, "app_version": this.app_version, }, { withCredentials: true, headers: { 'Authorization': this.token, 'Content-Type': 'text/html', }, responseType: 'blob', })
            .then(response => {
                    var blob = new Blob([response.data]);
                    FileSaver.saveAs(blob, my_filename);
            }, error => {
                console.log(error);
                this.$notify({
                  title: 'INFO',
                  message: "Report can't be created.",
                  type: 'warning',
                  position: 'top-right',
                });
            });

      },


      click_acmg (event, id_var) {
        event.stopImmediatePropagation()
        this.a_acmg_data( id_var )
      },


      click_freq_proj (row) {
        event.stopImmediatePropagation();
        this.freq_type = "List of samples sharing the same variants in current project";
        this.a_freq_data( {"row": row, "which": "proj"} );
      },


      click_freq_subj (row) {
        event.stopImmediatePropagation();
        this.freq_type = "List of samples sharing the same variants";
        this.a_freq_data( {"row": row, "which": "subj"} );
      },



      // setCurrent(row) {
      //   this.$refs.singleTable.setCurrentRow(row);
      // },
      // handleCurrentChange_p(val) {
      //   if (val && val.id_project > 0) {
      //     this.m_id_project(val.id_project);
      //     this.m_id_examination(0);
      //     this.m_pan_project(true);
      //     // this.a_examinations(val.id_project);
      //     this.project_name = val.name;
      //   } else {
      //     this.m_id_project(0);
      //     this.m_id_examination(0);
      //     this.m_pan_project(false);
      //     this.project_name = "";
      //   }
      // },

      // handleCurrentChange_e(val) {
      //   if (val && val.id_examination > 0) {
      //     this.m_id_examination(val.id_examination);
      //     this.a_vars( {"order": 1, "id_examination": val.id_examination} );
      //   } else {
      //     this.m_id_examination(0);
      //   }
      // },

      handleCurrentChange_v(val) {
        // console.log(val);
      },


      handleCurrentChangePage_v(val) {
        this.pagination_v.currentPage = val;

        if (this.vars_selected_tab === "x") {
          // this.cufi.cufi_yes = false;
          // this.cufi.full_yes = true;
          this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": this.pagination_v.currentPage, "fastras_freq": this.fastras_freq, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "selected": this.vars_selected } );
        } else if (this.vars_selected_tab === "b") {
          // this.cufi.cufi_yes = true;
          // this.cufi.full_yes = true;
          this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": this.pagination_v.currentPage, "fastras_freq": this.fastras_freq, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "cufi": this.cufi, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "selected": this.vars_selected } );
        } else if (this.vars_selected_tab === "c") {
          // this.cufi.cufi_yes = true;
          // this.cufi.full_yes = true;
          this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": this.pagination_v.currentPage, "fastras_freq": this.fastras_freq, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "cufi": this.cufi, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "selected": this.vars_selected } );
        } else {
          // this.cufi.cufi_yes = false;
          // this.cufi.full_yes = true;
          this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": this.pagination_v.currentPage, "fastras_freq": this.fastras_freq, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "cufi": this.cufi, "id_examination": this.id_examination, "selected_exams": this.selected_exams, "id_project": this.id_project, "selected": this.vars_selected } );
        }

      },


      handleSizeChangePage_v(val) {
        this.pagination_v.perPage = val;

        if (this.vars_selected_tab === "x") {
          // this.cufi.cufi_yes = false;
          // this.cufi.full_yes = true;
          this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "fastras_freq": this.fastras_freq, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "selected": this.vars_selected } );
        } else if (this.vars_selected_tab === "b") {
          // this.cufi.cufi_yes = true;
          // this.cufi.full_yes = true;
          this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "fastras_freq": this.fastras_freq, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "cufi": this.cufi, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "selected": this.vars_selected } );
        } else if (this.vars_selected_tab === "c") {
          // this.cufi.cufi_yes = true;
          // this.cufi.full_yes = true;
          this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "fastras_freq": this.fastras_freq, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "cufi": this.cufi, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "selected": this.vars_selected } );
        } else {
          // this.cufi.cufi_yes = false;
          // this.cufi.full_yes = true;
          this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "fastras_freq": this.fastras_freq, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "cufi": this.cufi, "id_examination": this.id_examination, "selected_exams": this.selected_exams, "id_project": this.id_project, "selected": this.vars_selected } );
        }

      },


      handleSelectionChange_v(val) {
        // event.stopImmediatePropagation();
        this.selected_vars = val;
      },


      handleSortChange_v(val) {
        // console.log(this.selected_exams);

        // event.stopImmediatePropagation();
        // console.log(val);
        this.pagination_v.currentPage = 1;
        this.sorting_v.prop = val.prop;
        this.sorting_v.ord = val.order;

        if (this.vars_selected_tab === "x") {
          // this.cufi.cufi_yes = false;
          // this.cufi.full_yes = true;
          this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": this.pagination_v.currentPage, "fastras_freq": this.fastras_freq, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "selected": this.vars_selected } );
        } else if (this.vars_selected_tab === "b") {
          // this.cufi.cufi_yes = true;
          // this.cufi.full_yes = true;
          this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": this.pagination_v.currentPage, "fastras_freq": this.fastras_freq, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "cufi": this.cufi, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "selected": this.vars_selected } );
        } else if (this.vars_selected_tab === "c") {
          // this.cufi.cufi_yes = true;
          // this.cufi.full_yes = true;
          this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": this.pagination_v.currentPage, "fastras_freq": this.fastras_freq, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "cufi": this.cufi, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "selected": this.vars_selected } );
        } else {
          // this.cufi.cufi_yes = false;
          // this.cufi.full_yes = true;
          this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": this.pagination_v.currentPage, "fastras_freq": this.fastras_freq, "search_vars_by_select": this.search_vars_by_select, "search_vars_by_value": this.search_vars_by_value, "cufi": this.cufi, "id_examination": this.id_examination, "selected_exams": this.selected_exams, "id_project": this.id_project, "selected": this.vars_selected } );
        }

      },


      toggleSelection_v(rows) {
        if (rows) {
          rows.forEach(row => {
            this.$refs.varsTable.toggleRowSelection_v(row);
          });
        } else {
          this.$refs.varsTable.clearSelection();
        }
      },


      handleCurrentChange_ff(val) {
        if (val && val.id_filter > 0) {
          console.log(val);
          // this.a_examinations(val.id_examination);
        } else {
          console.log(val);
          // this.m_i_examination(0);
        }
      },


      handleCurrentChange_hpo(val) {
        let skuplek = [];

        if (val) {
          for (let a of this.cufi.genes.values()) {
            skuplek.push(a);
          }
          for (let s of val.genelist) {
            if (!(skuplek.includes(s))) {
              skuplek.push(s);
            }
          }
          skuplek.sort();
          let mykey = "genes";
          let value = skuplek;
          this.$store.commit('m_cufi', {mykey, value});
        }
        this.a_notify( {message: "Genes were added to Filter"} );

        // axios.post(process.env.VUE_APP_BE + '/cufi', {cufi: this.cufi}, { withCredentials: true, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8' } })
        //   .then(response => {
        //     this.m_d_cufis( response.data["d_cufis"] );
        //     this.m_l_cufis( response.data["l_cufis"] );

        //     this.$notify({
        //       title: 'INFO',
        //       message: 'Filter was saved',
        //       type: 'success',
        //       position: 'top-right',
        //     });
        //   }, error => {
        //     console.log(error);
        //   });

      },


      inputFile: function (newFile, oldFile) {
        if (newFile && !newFile.active) {
          // Get response data
          console.log('response', newFile.response)
          if (newFile.xhr) {
            //  Get the response status code
            console.log('status', newFile.xhr.status)
          }
        }
      },


      watch_rapp () {
        if (this.rapp === "fastras") {
          this.user_setup.vars_grid_size = "S";
        } else {
          this.user_setup.vars_grid_size = "L";
        }
      },


      watch_id_examination (newid, oldid) {
        if (newid !== oldid) {
          if (this.igv_switch) {
            this.m_igv_switch(false);
          }
        }
      },

      // watch_selected_exams (newid, oldid) {
      //   if (newid.length > 0) {
      //       this.m_igv_switch(false);
      //   } else {
      //     this.m_igv_switch(true);
      //   }
      // },



    },

    beforeMount () {
      // this.a_vars();
      this.m_vars([]);
      moment.locale("us");
      this.formdata_np.created_dt = moment(Date().toLocaleString()).format('LLL');
      this.a_f_filters();
    },


    created () {

    },


    mounted () {
      // var igvDiv = document.getElementById("igv-div");
      // var options =
      //     {
      //         genome: "hg38",
      //         locus: "chr8:127,736,588-127,739,371",
      //         tracks: [
      //             {
      //                 "name": "HG00103",
      //                 "url": "https://s3.amazonaws.com/1000genomes/data/HG00103/alignment/HG00103.alt_bwamem_GRCh38DH.20150718.GBR.low_coverage.cram",
      //                 "indexURL": "https://s3.amazonaws.com/1000genomes/data/HG00103/alignment/HG00103.alt_bwamem_GRCh38DH.20150718.GBR.low_coverage.cram.crai",
      //                 "format": "cram"
      //             }
      //         ]
      //     };

      //     igv.createBrowser(igvDiv, options)
      //             .then(function (browser) {
      //                 console.log("Created IGV browser");
      //             })


    },


    afterDestroy () {
      this.m_vars([]);
    },


    watch: {
      rapp: {
        handler: 'watch_rapp',
        immediate: true
      },

      id_examination: {
        handler: 'watch_id_examination',
        immediate: true
      },

      // selected_exams: {
      //   handler: 'watch_selected_exams',
      //   immediate: true
      // },

    },


  }
</script>

<style lang="scss" scoped>
  body {
    margin: 0;
  }

  .el-table .td-actions{
    button.btn {
      margin-right: 5px;
    }
  }

  .btn {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .el-table {
    // font-size: 13px !important;
    // color: black !important;

  }

  .el-table .cell{
    line-height: 0px !important;
    word-break: break-word !important;
  }

  .el-table td, .el-table th {
    padding: 3px 0 !important;
    padding-top: 10px !important;
  }

  .el-table .row_0 {
    background: #e6e6e6 !important;
  }

  .el-table .row_1 {
    background: rgb(243, 245, 152) !important;
  }

  .el-table .row_2 {
    background: rgb(248, 221, 171) !important;
  }

  .el-table .row_3 {
    background: rgb(248, 186, 157) !important;
  }

  .el-table .row_4 {
    background: rgb(197, 248, 208) !important;
  }

  .el-table .row_9 {
    background: rgb(250, 197, 184) !important;
  }



  .el-table .warning-row {
    background: rgb(241, 175, 52) !important;
  }

  .el-table .success-row {
    background: #a7ffb9 !important;
  }

  .el-table .danger-row {
    background: #f34c19 !important;
  }

  .el-table .info-row .el-tabs--border-card {
    background: #ffece8 !important;
  }

  .hranaty {
    border-radius: 2px !important;
  }

  h4 {
    margin-top: 5px !important;
  }

  .dolu {
    padding-top: 80px !important;
  }

  .table > thead > tr > th, .el-table table > thead > tr > th {
    // font-size: 13px !important;
  }

  .mezera {
    margin-right: 10px !important;
  }

  .bottom_margin_30 {
    margin-bottom: 30px;
  }

  .bottom_margin_10 {
    margin-bottom: 10px;
  }

  .top_margin_10 {
    margin-top: 10px;
  }

  .top_margin_30 {
    margin-top: 30px;
  }

  .top_margin_50 {
    margin-top: 50px;
  }

  .bg_gray {
    background: #e2e4e5 !important;
  }

  .bg_white {
    background-color: #FFF !important;
  }

  .gene_blue {
    background-color: #ccfdf3 !important;
    margin-left: 0px !important;
    border-radius: 6px !important;
  }

  .el-tag {
      border-radius: 2px !important;
      margin-left: 3px !important;
      margin-bottom: 3px !important;
      color: black !important;
      font-weight: 600 !important;
  }

  .bg_white_tab {
    background-color: #FFF !important;
    z-index: 9999 !important;
  }

  .el-row {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #eee;
    min-height: 12px;
    padding-left: 4px !important;
  }

  .grid-content {
    border-radius: 4px;
    min-height: 18px;
  }

  .row-bg {
    padding: 3px 0;
    background-color: #f9fafc;
  }
  .el-table .cell {
      word-break: break-word;
  }

  .small_label {
      font-size: 10px !important;
      padding-top: 2px !important;
      padding-right: 4px !important;
  }

  .min_height {
    border-radius: 4px;
    height: 30px !important;
  }

  .popover_bg {
    background: #a3bbf1;
  }

  .pagination_mezera {
    margin-top: 25px !important;
  }

  .caret-wrapper {
    display: block !important;
  }

  .igv_modra {
    color: #409EFF !important;
  }

  .badge_item {
    margin-top: 10px;
    margin-right: 10px;
  }
</style>
